<template>
  <div class="page_bg">
    <div class="ship_bg_cart" :style="{background:'url('+ info.headImageUrl +')'}">
      <div class="ship_contant" style="border-radius:0.7rem ">
        <div class="ship_contant_pro_cart flex-c-c-c" style="">
          <div v-for="(item,index) in proList" :key="index" class="company_pro_list">
            <div class="flex-r-s-c">
              <proCard08 :info="item" :pmid="proSearch.productModuleId" @changeNum="changeNum" />
            </div>
          </div>
        </div>
      </div>

      <!-- 底部操作 -->
      <div class="classify_sub_cart flex-r-sa-c">
        <div class="classify_sub_cart_num" @click="toView">
          <van-image v-if="cartNum > 0" width="1.39rem" height="1.39rem"
                     src="https://oss.xiaoyi120.com/shop2.0/product/classify_cart11.png"
          />
          <van-image v-else width="1.39rem" height="1.39rem"
                     src="https://oss.xiaoyi120.com/shop2.0/product/classify_cart10.png"
          />
          <div v-if="cartNum > 0" :class="cartNum > 9 ? 'nums_posite2':'nums_posite1' ">{{ cartNum }}</div>
        </div>
        <div class="classify_sub_cart_info flex-c-s-s">
          <div class="info_money">实付金额:<span style="color:#E22D1D">￥</span><span
            style=" font-size: 0.48rem;color:#E22D1D"
          >{{ totalMoney }}</span></div>
          <div class="info_money2">优惠减￥{{ discountPrice }}</div>
        </div>
        <div />
        <div />
        <div />
        <div />
        <div :class="cartNum > 0 ? 'classify_sub_cart_button1':'classify_sub_cart_button0'" @click="toView">订单预览</div>
      </div>
    </div>

    <!-- 资质认证弹窗 -->
    <van-dialog v-model="certificationShow" :show-confirm-button="false">
      <div class="mask flex-r-c-c">
        <div class="certification_dialog flex-c-c-c">
          <van-image width="4.11rem" height="2.75rem"
                     src="https://oss.xiaoyi120.com/shop2.0/home/certification.png"
          />
          <div class="certification_title"> 您尚未提交认证资料 </div>
          <div class="certification_tip"> 暂时无法采购 </div>
          <div class="flex-r-sa-c">
            <van-button class="loginOut_btn" round type="info" plain color="#0767ab" @click="loginOut">重新登录
            </van-button>
            <van-button class="certification_btn" round type="info" color="#0767ab" @click="toUpCertification">立即认证
            </van-button>
          </div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import './index.scss'
import proCard08 from '@/components/pro_card_08_cart'
import { mallPreferential_cartCalculate } from '@/api/pro'
import { mallPreferential_productList, productModule_detail } from '@/api/pro'
export default {
  name: 'ShiplistFastCart',
  components: { proCard08 },
  data() {
    return {
      price: null,
      info: null,
      // 商品列表
      proSearch: { pageNum: 1, pageSize: 999, productModuleId: '' },
      proList: [],

      cartNum: 0,
      discountPrice: '0.00',
      totalMoney: '0.00',

      certificationShow: false
    }
  },
  created() {
    this.proSearch.productModuleId = this.$route.query.productModuleId
    this.getProList()
    this.getNum()
    this.getDetail()
    if (window.name === 'proDetail' || window.name === '') {
      console.log('created 首次被加载')
      window.name = 'proDetail' // 在首次进入页面时我们可以给window.name设置一个固定值
      sessionStorage.removeItem('jump')
      sessionStorage.setItem('shareUrl', '/order/shiplist_fastCart?productModuleId=' + this.proSearch.productModuleId)
    } else if (window.name === 'isReload') {
      sessionStorage.setItem('jump', true)
    }
  },
  activated() {
    console.log(sessionStorage.getItem('cart_pro_id'))
    this.getNum()
    console.log('keepalive 进入classify1', parseInt(this.$route.query.reload))
    if (sessionStorage.getItem('cart_pro_detail')) {
      console.log('keepalive 进入classify1 2')
      this.proList = []
      this.getProList()
      sessionStorage.removeItem('cart_pro_detail')
    }
    if (sessionStorage.getItem('tabberJump')) {
      // this.$router.go(0)
      // window.location.reload()
      sessionStorage.removeItem('tabberJump')
    }
    this.getDetail()
  },
  deactivated() {
    console.log('keepalive 离开classify')
  },
  methods: {
    getDetail() {
      productModule_detail({ productModuleId: this.proSearch.productModuleId }).then(res => {
        if (res.data.status === 0) {
          window.document.title = res.data.productModuleName
          this.info = res.data
          this.shareDetail()
        } else { this.$router.push('/order/shiplistNotWork?type=1&orginal=2&productModuleName=' + res.data.productModuleName) }
      })
    },
    loadNowProduct() {
      var cart_pro_id = JSON.parse(sessionStorage.getItem('cart_pro_id'))
      console.log('cart_pro_id', sessionStorage.getItem('cart_pro_id'))
      var productId = cart_pro_id.productId
      var num = cart_pro_id.num
      console.log(productId, num)
      for (const i in this.proList) {
        if (this.proList[i].productId === productId) { this.proList[i].num = num }
      }
      console.log('this.proList', this.proList)
      setTimeout(() => {
        this.$nextTick(() => { sessionStorage.removeItem('cart_pro_id') })
      }, 100)
    },
    // 获取列表
    getProList() {
      mallPreferential_productList(this.proSearch).then(res => {
        this.proList = res.data.list
        this.$forceUpdate()
      })
    },
    getNum() {
      mallPreferential_cartCalculate({ productModuleId: this.proSearch.productModuleId }).then(res => {
        this.cartNum = res.data.typeNum
        this.discountPrice = res.data.discountPrice
        this.totalMoney = res.data.totalPrice
      })
      if (sessionStorage.getItem('cart_pro_id')) { this.loadNowProduct() }
    },
    // 修改商品数量
    changeNum(num) { this.getNum() },

    toView() {
      if (this.cartNum > 0) {
        let url = ''
        url = `/order/subOrder_cart?productModuleId=` + this.proSearch.productModuleId
        this.$router.push(url)
      } else {
        this.$toast({ message: '请先添加商品', duration: 1 * 1000 })
      }
    },
    // 去认证
    toUpCertification() { this.$router.push('/home/certification') },

    loginOut() {
      this.$router.push('/user/login')
      var path = this.$route.path
      sessionStorage.setItem('shareUrl', path)
    },

    // ---------------------------------- 分享配置 ----------------------------------
    shareDetail() {
      var that = this
      this.$wechat.share({
        title: that.info.productModuleName,
        img: that.info.headImageUrl,
        desc: that.info.description
      })
    }
  }
}
</script>

<style>
</style>
