<template>
  <div class="product_box cart_product_box_cart flex-r-c-c">
    <div class="pro_info flex-r-s-c">
      <van-image width="2.56rem" height="2.56rem" :src="myinfo.saleIconUrl " @click="jumpDetail(myinfo.productId)" />
      <div class="cart_pro_info" style="width:6.6rem;height:2.56rem;position: relative;">
        <div class="cart_pro_info_top">
          <div class="pro_name van-multi-ellipsis--l2" style="width:6.2rem;margin-top:0rem"
               @click="jumpDetail(myinfo.productId)"
          >
            {{ myinfo.saleName }}</div>
          <div class="pro_brand_model van-ellipsis" style="width:6.2rem" @click="jumpDetail(myinfo.productId)">
            {{ myinfo.proBrand }} | {{ myinfo.proModel }}</div>
        </div>
        <!-- <div class="pro_tags">1</div> -->
        <div class="pro_bottom pro_bottom_cart  flex-r-sb-c" style="width:6.2rem">
          <div class="pro_money flex-r-s-s">
            <div>￥<span style="font-size: 0.49rem;">{{ myinfo.salePrice }}</span></div>
            <div v-if="myinfo.saleUnderlineFlag === 1" style="font-size: 0.3rem;color:rgb(153, 153, 153);text-decoration:line-through;margin-left:0.1rem">￥<span>{{ myinfo.proPrice }}</span></div>
          </div>
          <div class="pro_btn flex-r-c-c">
            <van-stepper v-if="myinfo.num > 0 || myinfo.num === ''" v-model="myinfo.num" :step="myinfo.minQuantity"
                         :min="0" integer :allow-empty="true" input-width="1rem" button-size="0.5rem" theme="round"
                         @change="changeNum" @focus="focusInput" @blur="blurInput"
            />
            <!-- $event.target.select() -->
            <van-icon v-else name="add" color="#0767AB" size="0.55rem" @click="addNum" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mallPreferential_addCart, mallPreferential_delCart } from '@/api/pro'
export default {
  props: {
    info: { type: Object, default: () => { } },
    id: { type: Number, default: null },
    pmid: { type: String, default: '' },
    length: { type: Number, default: null },
    cannum: { type: Number, default: null }
  },
  data() {
    return {
      myinfo: this.info,
      mycanBuyNum: this.cannum === -1 ? 9999 : this.cannum
    }
  },
  methods: {

    // 进入详情
    jumpDetail(productId) {
      sessionStorage.setItem('classify_pro_id', 'pro_' + this.id)
      this.$router.push('/product/detail_cart?productId=' + productId + '&productModuleId=' + this.pmid)
    },
    focusInput() { this.myinfo.num = '' },
    blurInput() {
      if (this.myinfo.num === '') {
        mallPreferential_delCart({ productId: this.myinfo.productId, productModuleId: this.pmid }).then(res => { this.$emit('changeNum', res.data) })
        this.myinfo.num = 0
      }
    },
    addNum() {
      mallPreferential_addCart({ productId: this.myinfo.productId, productModuleId: this.pmid, num: parseInt(this.myinfo.minQuantity) }).then(res => {
        this.myinfo.num = parseInt(this.myinfo.minQuantity)
        this.$emit('changeNum', res.data)
      })
    },
    // 修改数量
    changeNum(val) {
      var minQuantity = parseInt(val) % parseInt(this.myinfo.minQuantity) === 0
      console.log(minQuantity)
      if (parseInt(val) > 0 && minQuantity) {
        mallPreferential_addCart({ productId: this.myinfo.productId, num: val, productModuleId: this.pmid }).then(res => {
          this.$emit('changeNum', res.data)
        })
      } else if (parseInt(val) === 0) {
        mallPreferential_delCart({ productId: this.myinfo.productId, productModuleId: this.pmid }).then(res => { this.$emit('changeNum', res.data) })
        this.myinfo.num = 0
      }
      this.$forceUpdate()
    }
  }
}
</script>

<style>
.pro_btn .van-stepper--round .van-stepper__minus {
  border: 0.03rem solid #e2e2e2;
  color: #000;
}
.pro_btn .van-stepper--round .van-stepper__plus {
  background-color: rgba(7, 103, 171, 1);
}
.product_box_cart {
  border-bottom: 2px solid #f7f7f7;

  margin-top: 0rem;
}
.cart_product_box_cart {
  width: 9.47rem;
  height: 3rem;
  border-radius: 0.27rem;
  background: #ffffff;
}
.cart_pro_info_top {
  position: absolute;
  top: 0;
}
.pro_bottom_cart {
  position: absolute;
  bottom: 0;

  margin: 0 !important;
}
</style>
